import { Control, Controller, FieldPath, FieldValues, UseControllerProps } from "react-hook-form";
import { RestProps } from "types/common";
import clsx from "clsx";
import { useStyles } from "./styles";
import { ChangeEventHandler } from "react";
import { TextField } from "@scaleaq/scaleui";

export type Props<FormType = any> = {
  control?: Control<FormType>;
  name: FieldPath<FormType> extends never ? string : FieldPath<FormType>;
  type?: string;
  label?: string;
  rules?: UseControllerProps["rules"];
  disabled?: boolean;
  defaultValue?: string | number;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  autoFocus?: boolean;
  suffix?: string;
  width?: string;
} & RestProps;

export const ReactHookFormTextField = <T,>(props: Props<T>) => {
  const {
    name,
    label,
    control,
    rules,
    disabled,
    defaultValue,
    onChange: onChangeProp,
    className,
    type,
    suffix,
    width,
    ...rest
  } = props;

  const { classes } = useStyles(props);

  return (
    <Controller
      name={name}
      control={control as Control<FieldValues>}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <TextField
          id={name}
          label={label}
          className={clsx(className, classes.input, type === "number" ? classes.number : "")}
          style={{ textAlign: "left" }}
          disabled={disabled}
          width={width || "216px"}
          type={type}
          onChange={(e) => {
            if (onChangeProp) {
              onChangeProp(e as any);
            }
            onChange(e);
          }}
          value={value ?? ""}
          error={error?.message ?? ""}
          suffix={suffix}
          {...rest}
        />
      )}
    />
  );
};
