// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * Users API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { B2CErrorResponse } from '../model';
// @ts-ignore
import { B2CLoginResponseWeb } from '../model';
// @ts-ignore
import { LoginRequestBody } from '../model';
// @ts-ignore
import { PostUserByAlternativeSecurityIdRequestBody } from '../model';
// @ts-ignore
import { PostUserByAlternativeSecurityIdRequestResponse } from '../model';
// @ts-ignore
import { ProfileUpdateRequestBody } from '../model';
// @ts-ignore
import { UserAssignableCompaniesResponse } from '../model';
/**
 * B2CApi - axios parameter creator
 * @export
 */
export const B2CApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1B2cCultureinfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/b2c/cultureinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userObjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1B2cUserassignablecompaniesUserObjectId: async (userObjectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userObjectId' is not null or undefined
            assertParamExists('getV1B2cUserassignablecompaniesUserObjectId', 'userObjectId', userObjectId)
            const localVarPath = `/v1/b2c/userassignablecompanies/{userObjectId}`
                .replace(`{${"userObjectId"}}`, encodeURIComponent(String(userObjectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint called from B2C every time a user logs in.   Used to keep federated users in sync (with regards to companyId).
         * @param {LoginRequestBody} [loginRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1B2cLogin: async (loginRequestBody?: LoginRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/b2c/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProfileUpdateRequestBody} [profileUpdateRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1B2cProfileupdate: async (profileUpdateRequestBody?: ProfileUpdateRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/b2c/profileupdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(profileUpdateRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostUserByAlternativeSecurityIdRequestBody} [postUserByAlternativeSecurityIdRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1B2cUserbyalternativesecurityid: async (postUserByAlternativeSecurityIdRequestBody?: PostUserByAlternativeSecurityIdRequestBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/b2c/userbyalternativesecurityid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json;odata.metadata=minimal;odata.streaming=true';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserByAlternativeSecurityIdRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * B2CApi - functional programming interface
 * @export
 */
export const B2CApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = B2CApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1B2cCultureinfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1B2cCultureinfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userObjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1B2cUserassignablecompaniesUserObjectId(userObjectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAssignableCompaniesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1B2cUserassignablecompaniesUserObjectId(userObjectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Endpoint called from B2C every time a user logs in.   Used to keep federated users in sync (with regards to companyId).
         * @param {LoginRequestBody} [loginRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1B2cLogin(loginRequestBody?: LoginRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<B2CLoginResponseWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1B2cLogin(loginRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProfileUpdateRequestBody} [profileUpdateRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1B2cProfileupdate(profileUpdateRequestBody?: ProfileUpdateRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileUpdateRequestBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1B2cProfileupdate(profileUpdateRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PostUserByAlternativeSecurityIdRequestBody} [postUserByAlternativeSecurityIdRequestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1B2cUserbyalternativesecurityid(postUserByAlternativeSecurityIdRequestBody?: PostUserByAlternativeSecurityIdRequestBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostUserByAlternativeSecurityIdRequestResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postV1B2cUserbyalternativesecurityid(postUserByAlternativeSecurityIdRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * B2CApi - factory interface
 * @export
 */
export const B2CApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = B2CApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1B2cCultureinfo(options?: AxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.getV1B2cCultureinfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {B2CApiGetV1B2cUserassignablecompaniesUserObjectIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1B2cUserassignablecompaniesUserObjectId(requestParameters: B2CApiGetV1B2cUserassignablecompaniesUserObjectIdRequest, options?: AxiosRequestConfig): AxiosPromise<UserAssignableCompaniesResponse> {
            return localVarFp.getV1B2cUserassignablecompaniesUserObjectId(requestParameters.userObjectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint called from B2C every time a user logs in.   Used to keep federated users in sync (with regards to companyId).
         * @param {B2CApiPostV1B2cLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1B2cLogin(requestParameters: B2CApiPostV1B2cLoginRequest = {}, options?: AxiosRequestConfig): AxiosPromise<B2CLoginResponseWeb> {
            return localVarFp.postV1B2cLogin(requestParameters.loginRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {B2CApiPostV1B2cProfileupdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1B2cProfileupdate(requestParameters: B2CApiPostV1B2cProfileupdateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ProfileUpdateRequestBody> {
            return localVarFp.postV1B2cProfileupdate(requestParameters.profileUpdateRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {B2CApiPostV1B2cUserbyalternativesecurityidRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1B2cUserbyalternativesecurityid(requestParameters: B2CApiPostV1B2cUserbyalternativesecurityidRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PostUserByAlternativeSecurityIdRequestResponse> {
            return localVarFp.postV1B2cUserbyalternativesecurityid(requestParameters.postUserByAlternativeSecurityIdRequestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * B2CApi - interface
 * @export
 * @interface B2CApi
 */
export interface B2CApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApiInterface
     */
    getV1B2cCultureinfo(options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 
     * @param {string} userObjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApiInterface
     */
    getV1B2cUserassignablecompaniesUserObjectId(userObjectId: string, options?: AxiosRequestConfig): AxiosPromise<UserAssignableCompaniesResponse>;

    /**
     * 
     * @summary Endpoint called from B2C every time a user logs in.   Used to keep federated users in sync (with regards to companyId).
     * @param {LoginRequestBody} [loginRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApiInterface
     */
    postV1B2cLogin(loginRequestBody?: LoginRequestBody, options?: AxiosRequestConfig): AxiosPromise<B2CLoginResponseWeb>;

    /**
     * 
     * @param {ProfileUpdateRequestBody} [profileUpdateRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApiInterface
     */
    postV1B2cProfileupdate(profileUpdateRequestBody?: ProfileUpdateRequestBody, options?: AxiosRequestConfig): AxiosPromise<ProfileUpdateRequestBody>;

    /**
     * 
     * @param {PostUserByAlternativeSecurityIdRequestBody} [postUserByAlternativeSecurityIdRequestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApiInterface
     */
    postV1B2cUserbyalternativesecurityid(postUserByAlternativeSecurityIdRequestBody?: PostUserByAlternativeSecurityIdRequestBody, options?: AxiosRequestConfig): AxiosPromise<PostUserByAlternativeSecurityIdRequestResponse>;

}

/**
 * Request parameters for getV1B2cUserassignablecompaniesUserObjectId operation in B2CApi.
 * @export
 * @interface B2CApiGetV1B2cUserassignablecompaniesUserObjectIdRequest
 */
export interface B2CApiGetV1B2cUserassignablecompaniesUserObjectIdRequest {
    /**
     * 
     * @type {string}
     * @memberof B2CApiGetV1B2cUserassignablecompaniesUserObjectId
     */
    readonly userObjectId: string
}

/**
 * Request parameters for postV1B2cLogin operation in B2CApi.
 * @export
 * @interface B2CApiPostV1B2cLoginRequest
 */
export interface B2CApiPostV1B2cLoginRequest {
    /**
     * 
     * @type {LoginRequestBody}
     * @memberof B2CApiPostV1B2cLogin
     */
    readonly loginRequestBody?: LoginRequestBody
}

/**
 * Request parameters for postV1B2cProfileupdate operation in B2CApi.
 * @export
 * @interface B2CApiPostV1B2cProfileupdateRequest
 */
export interface B2CApiPostV1B2cProfileupdateRequest {
    /**
     * 
     * @type {ProfileUpdateRequestBody}
     * @memberof B2CApiPostV1B2cProfileupdate
     */
    readonly profileUpdateRequestBody?: ProfileUpdateRequestBody
}

/**
 * Request parameters for postV1B2cUserbyalternativesecurityid operation in B2CApi.
 * @export
 * @interface B2CApiPostV1B2cUserbyalternativesecurityidRequest
 */
export interface B2CApiPostV1B2cUserbyalternativesecurityidRequest {
    /**
     * 
     * @type {PostUserByAlternativeSecurityIdRequestBody}
     * @memberof B2CApiPostV1B2cUserbyalternativesecurityid
     */
    readonly postUserByAlternativeSecurityIdRequestBody?: PostUserByAlternativeSecurityIdRequestBody
}

/**
 * B2CApi - object-oriented interface
 * @export
 * @class B2CApi
 * @extends {BaseAPI}
 */
export class B2CApi extends BaseAPI implements B2CApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApi
     */
    public getV1B2cCultureinfo(options?: AxiosRequestConfig) {
        return B2CApiFp(this.configuration).getV1B2cCultureinfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {B2CApiGetV1B2cUserassignablecompaniesUserObjectIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApi
     */
    public getV1B2cUserassignablecompaniesUserObjectId(requestParameters: B2CApiGetV1B2cUserassignablecompaniesUserObjectIdRequest, options?: AxiosRequestConfig) {
        return B2CApiFp(this.configuration).getV1B2cUserassignablecompaniesUserObjectId(requestParameters.userObjectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint called from B2C every time a user logs in.   Used to keep federated users in sync (with regards to companyId).
     * @param {B2CApiPostV1B2cLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApi
     */
    public postV1B2cLogin(requestParameters: B2CApiPostV1B2cLoginRequest = {}, options?: AxiosRequestConfig) {
        return B2CApiFp(this.configuration).postV1B2cLogin(requestParameters.loginRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {B2CApiPostV1B2cProfileupdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApi
     */
    public postV1B2cProfileupdate(requestParameters: B2CApiPostV1B2cProfileupdateRequest = {}, options?: AxiosRequestConfig) {
        return B2CApiFp(this.configuration).postV1B2cProfileupdate(requestParameters.profileUpdateRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {B2CApiPostV1B2cUserbyalternativesecurityidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof B2CApi
     */
    public postV1B2cUserbyalternativesecurityid(requestParameters: B2CApiPostV1B2cUserbyalternativesecurityidRequest = {}, options?: AxiosRequestConfig) {
        return B2CApiFp(this.configuration).postV1B2cUserbyalternativesecurityid(requestParameters.postUserByAlternativeSecurityIdRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
