import { makeStyles } from "tss-react/mui";
import { Props } from "./index";

export const useStyles = makeStyles<Props>()(() => {
  return {
    input: {
      "& label[data-shrink='false'] + .MuiInputBase-formControl .MuiInputBase-input::placeholder": {
        opacity: "0.42 !important",
      },
    },
    number: {
      input: {
        textAlign: "right",
      },
    },
  };
});
