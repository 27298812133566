import { userApiBaseUrl } from "utils/appsettings";
import * as UsersApi from "api/swagger/users.api.1.0/api";
import { generateSwaggerApiClient } from "./genericApiClient";

const usersApiV1Client = generateSwaggerApiClient(UsersApi, userApiBaseUrl);
export * as UsersApiModels from "api/swagger/users.api.1.0/model";

export enum SignInType {
  Federated = "federated",
  EmailAddress = "emailAddress",
}

export default usersApiV1Client;
